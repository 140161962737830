<template>
  <section >
    <div class="container">
      <div class=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                  pb-2
                  ps-0
                  pe-0">
        <div class="partnership-banner-items-container">
          <div class="row align-items-center partnership-main-banner-item">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <p class="banner-content h1 text-main">
                <span class="text-blue">Ödəniş </span> et
              </p>
              <p class="banner-text mt-3">
                Sığorta haqqının ödənişlərini Siz terminallar vasitəsilə nağd və ya onlayn ödəyə bilərsiniz. 
              </p>
            </div>
            <div class=" col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 payment-banner_img">
              <img src="@/assets/images/payment-banner.png" class="partner_bg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container payment-banner-content">
      <div class=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-5 pl-0 pr-0 mx-auto mb-4  mt-2">
        <div class="row rounded py-4  text-start justify-content-between">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <p class="about-text">
              Nağd ödəniş:
            </p>
            <div class="payment-service-item mt-4 d-flex align-items-start">
              <img src="@/assets/images/payment-step-1.svg" />
              <span class="ms-3">
                Terminala yaxınlaşın
              </span>
            </div>
            <div class="payment-service-item d-flex align-items-start">
              <img src="@/assets/images/payment-step-2.svg" />
              <span class="ms-3">
                “Sığorta” səhifəsinə daxil olun
              </span>
            </div>
            <div class="payment-service-item  d-flex align-items-start">
              <img src="@/assets/images/payment-step-3.svg" />
              <span class="ms-3">
                Sığorta növünü seçin <br>
                <span class="payment-service-item-text">(“İcbarı avtonəqliyyat sığortası”, Digər sığorta şirkəti)</span>
              </span>
            </div>
            <div class="payment-service-item  d-flex align-items-start">
              <img src="@/assets/images/payment-step-4.svg" />
              <span class="ms-3">
                Ödəniş kodu (40000.......), <br>
                <span class="payment-service-item-text">“Şəhadətnamə nömrəsi” və ya FİN</span>
              </span>
            </div>
            <div class="payment-service-item d-flex align-items-start">
              <img src="@/assets/images/payment-step-5.svg" />
              <span class="ms-3">
                Ödənişi tamamlayın.
              </span>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <p class="about-text">
              Onlayn ödəniş
            </p>
            <div class="payment-service-item mt-4 d-flex align-items-start">
              <img src="@/assets/images/payment-step-1.svg" />
              <span class="ms-3">
                Onlayn ödəniş saytına daxil olun
              </span>
            </div>
            <div class="payment-service-item mt-4 d-flex align-items-start">
              <img src="@/assets/images/payment-step-2.svg" />
              <span class="ms-3">
                “Sığorta” səhifəsini seçin
              </span>
            </div>
            <div class="payment-service-item mt-4 d-flex align-items-start">
              <img src="@/assets/images/payment-step-3.svg" />
              <span class="ms-3">
                Sığorta növünü seçin <br>
                <span class="payment-service-item-text">(“İcbarı Sığorta Bürosu”, Digər sığorta şirkəti)</span>
              </span>
            </div>
            <div class="payment-service-item mt-4 d-flex align-items-start">
              <img src="@/assets/images/payment-step-4.svg" />
              <span class="ms-3">
                Ödəniş kodu (40000.......), <br>
                <span class="payment-service-item-text">“Şəhadətnamə nömrəsi” və ya FİN</span>
              </span>
            </div>
            <div class="payment-service-item mt-4 d-flex align-items-start">
              <img src="@/assets/images/payment-step-5.svg" />
              <span class="ms-3">
                Bank kart məlumatları daxil edin
              </span>
            </div>
            <div class="payment-service-item mt-4 d-flex align-items-start">
              <img src="@/assets/images/payment-step-6.svg" />
              <span class="ms-3">
                Ödənişi tamamlayın.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-4">
        <p class="select-payment-text pb-3">
          <span class="text-blue">Onlayn</span> ödəniş etmək üçün seçin:
        </p>
        <a target="_blank" href="https://gpp.az/WebPortal/Infosite"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 mt-3">
          <div class="
                select-payment-item
              ">
            <img src="@/assets/images/e-gov.svg" />
          </div>
        </a>
        <a target="_blank" href="https://www.million.az/" class="col-xs-12 colsm-12 col-md-12 col-lg-12 col-xl-3 mt-3">
          <div class="
            select-payment-item
              ">
            <img src="@/assets/images/million.svg" />
          </div>
        </a>
        <a target="_blank" href="https://epay.az/" class="col-xs-12 colsm-12 col-md-12 col-lg-12 col-xl-3 mt-3">
          <div class="
            select-payment-item
              ">
            <img src="@/assets/images/epay.svg" />
          </div>
        </a>
        <a target="_blank" href="https://epul.az/" class="col-xs-12 colsm-12 col-md-12 col-lg-12 col-xl-3 mt-3">
          <div class="
            select-payment-item
              ">
            <img src="@/assets/images/epul.svg" />
          </div>
        </a>
      </div>
      <p class="pay-info">*İcbari avtonəqliyyat sığortası ödənişləri İcbarı Sığorta Bürosu (İSB)-na ödənilir.
        <br> Könüllü növlər üzrə ödənişlər birbaşa sığorta şirkətlərinə ödənilir.
      </p>
    </div>
  </section>
</template>




